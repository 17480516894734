<template>

    <div class="app-content content add-merchant-container">
        <div class="container-fuild listing-container">
            <div class="grid m-0">
                <div class="col-12 md:col-6 lg:col-6 ps-0">
                    <div class="card-border-box">
                        <h2 class="listing-title mb-3">Contact Details</h2>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/sms.svg">
                                <inputText-prime v-model="current_merchant.email" class="form-input-fields" type="email" placeholder="Enter Your Email" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-4">
                            <span class="p-input-icon-left">
                                <vue-tel-input v-model="current_merchant.mobile" mode="international" placeholder="Write your mobile number"></vue-tel-input>
                            </span>
                        </div>
                    </div>

                    <div class="card-border-box">
                        <h2 class="listing-title mb-3">Brand Details</h2>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/name.svg">
                                <inputText-prime v-model="current_merchant.brand_name" class="form-input-fields" placeholder="Enter Brand Name" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/category.svg">
                                <multiSelect-prime v-model="current_merchant.selected_categories" :options="merchant_categories" optionLabel="name" filter placeholder="Select your Services Category " :maxSelectedLabels="5" class="w-full" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/category.svg">
                                <inputText-prime type="number" v-model="current_merchant.no_of_branches" :min="1" class="form-input-fields" placeholder="No of Branches" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                <select-prime v-model="current_merchant.selected_emirate" :options="emirates" optionLabel="name" placeholder="Select Emirate" class="w-full form-input-fields" />

                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                <inputText-prime class="form-input-fields" v-model="current_merchant.address" placeholder="Address" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-6 pe-0">
                    <div class="card-border-box">
                        <h2 class="listing-title mb-3" v-if="pdfUrl!=null">
                            Trade License
                            <span class="download-license-btn" @click="download_pdf">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-download">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                <path d="M7 11l5 5l5 -5" />
                                <path d="M12 4l0 12" />
                                </svg>
                                Download
                            </span>
                        </h2>

                        <div :class="['form-group', { hidden: pdfUrl == null && thumbnail == null }]">
                            <div class="upload-image-box upload-document-box uploaded-bg upload-pdf-image">
                                <canvas ref="pdfCanvas" style="display: none;"></canvas>
                                <img v-if="thumbnail" :src="thumbnail" alt="PDF Thumbnail" />
                            </div>
                        </div>

                        <div class="form-group">
                            <input id="file-upload" @change="handleFileUpload" type="file" name="fileUpload">
                            <label for="file-upload" id="file-drag">
                                <div class="upload-image-box upload-document-box">
                                    <div class="upload-icon">
                                        <img class="" src="../../assets/images/uplolad-doc.svg">
                                    </div>
                                    <p class="upload-box-label">Upload your file here.</p>
                                </div>
                            </label>
                        </div>

                        <p v-if="current_merchant.file_name!=null">{{ current_merchant.file_name }} {{ current_merchant.file_size }}</p>
                        <p v-else>Upload your Trade License in pdf form</p>
                    </div>

                </div>
            </div>

            <div class="grid m-0 mt-2">
                <div class="col-12 md:col-6 lg:col-6 ps-0">
                    <div class="col-12 md:col-6 lg:col-6 ps-0 gap-2 flex justify-content-start">
                        <button-prime class="wafa-primary-outline-btn add-marchat-btn" label="Go Back" @click="$emit('hide_merchant_detail', 'listing-page')" />
                    </div>
                </div>

                <div v-if="merchant.from=='respond'" class="col-12 md:col-6 lg:col-6 ps-0 gap-2 flex justify-content-end align-items-center">
                    <!-- <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="thumbnail" @click="generateThumbnail"></button-prime> -->
                    <button-prime class="wafa-primary-outline-btn" label="Reject License" :loading="reject_license_button_loader" @click="reject_license" />
                    <button-prime class="wafa-primary-btn add-marchat-btn" label="Validate and Save" @click="validate_and_save" />
                </div>
                <div v-if="merchant.from=='edit'" class="col-12 md:col-6 lg:col-6 ps-0 gap-2 flex justify-content-end align-items-center">
                    <button-prime class="wafa-primary-btn add-marchat-btn" label="Save" :loading="save_merchant_loader" @click="save_merchant" />
                </div>
            </div>
        </div>
    </div>

    <!-- Confirmation Modal -->
    <dialog-prime class="confirmation-plan-modal" v-model:visible="validate_and_save_modal" modal header="New Program" :style="{ width: '32rem' }">
    
        <div class="modal-body-container">
            <div class="confirmation-info w-full">
                <h2>Confirm Action</h2>
                <p>By continuing you understand that this will update merchant’s detail, merchant license will be marked validated and merchant’s privileges will be revived.</p>
            </div>
        </div>

        <div class="confirmation-footer-btn">
            <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="Confirm" @click="confirm_validate_and_save"></button-prime>

            
        </div>
    </dialog-prime>
    <!-- / Confirmation Modal -->
    <toast-prime />
</template>

<script>
import axios from 'axios'
import * as pdfjsLib from 'pdfjs-dist/webpack'; // Make sure this is correctly installed and set up


// import html2canvas from 'html2canvas';


export default {
    props: {
        merchant: Object,
        merchant_categories : Array,
        emirates : Array
    },
    data() {
        return {
            current_merchant : {
                email : null,
                brand_name : null,
                merchant_id : 0,
                mobile : null,
                selected_categories : [],
                selected_emirate : [],
                no_of_branches : 0,
                address : null,
                trade_license : null,
                trade_license_thumbnail : null,
                set_user_active : '1',
                user_id : 0,
                status : 'active',
                file : null,
                file_name : null,
                file_size : null,
                
            },
            validate_and_save_modal : false,
            reject_license_button_loader : false,
            save_merchant_loader: false,
            pdfUrl: null,
            thumbnail: null
        };
    },
    methods : {
        handleFileUpload(event) {
            const file = event.target.files[0];

            if (file) {
                // Validate file type (PDF only)
                if (file.type !== 'application/pdf') {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Only PDF files are allowed!', life: 3000 });
                    return;
                }

                // Validate file size (max 5MB)
                const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                if (file.size > maxSize) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'File size should not exceed 5MB!', life: 3000 });
                    return;
                }

               
                // Set file details
                this.current_merchant.file = file;
                this.current_merchant.file_name = file.name;
                this.current_merchant.file_size = this.formatFileSize(file.size);

                // Generate PDF thumbnail
                this.generateThumbnail1(file);
            }
        },
        formatFileSize(size) {
        if (size < 1024) {
            return size + ' Bytes';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        }
        },
        async generateThumbnail1(file) {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        
        // Load the PDF file with pdfjs
        const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
        const page = await pdf.getPage(1); // Get the first page

        const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale for thumbnail size
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render the PDF page as an image
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        await page.render(renderContext).promise;

        // Convert the canvas to a data URL (base64)
        const thumbnail = canvas.toDataURL('image/jpeg');
        this.thumbnail = thumbnail; // Save the thumbnail to use later
      };

      // Read the file as an ArrayBuffer
      fileReader.readAsArrayBuffer(file);
    },

        async generateThumbnail() {
            const canvas = this.$refs.pdfCanvas;
            const context = canvas.getContext('2d');

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 1 });

                // Adjust canvas size to match the PDF page
                canvas.width = viewport.width;
                canvas.height = viewport.height;

                // Render the PDF page to the canvas
                await page.render({
                canvasContext: context,
                viewport: viewport
                }).promise;

                // Convert the canvas content to a data URL
                this.thumbnail = canvas.toDataURL();
            } catch (error) {
                console.error('Error generating thumbnail:', error);
            }
    

    },

        send_welcome_email_to_merchant(){
            
            axios.post(this.api_url+'superadmin/welcome-merchant-with-credentials',
            {user_id : this.current_merchant.user_id},
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
            }
            }).then(response => {
                // if (response.data.data.status) {
                //     this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
                //     this.validate_and_save_modal = false;
                // } else {
                //     this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.data.message, life: 3000 });
                // }
                console.log('response', response.data.data)
            });
        },
        reject_license(){
            this.reject_license_button_loader = true;
            axios.post(this.api_url+'superadmin/update-merchant-data',
            {
                status : 'license-rejected',
                merchant_id : this.current_merchant.merchant_id
            },
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
            }
            }).then(response => {
                if (response.data.data.status) {
                    this.reject_license_button_loader = false;
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.data.message, life: 3000 });
                }
            });
        },
        save_merchant() {
            if (this.current_merchant.email == null || this.current_merchant.email == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact email required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.mobile == null || this.current_merchant.mobile == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact phone required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.brand_name == null || this.current_merchant.brand_name == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Brand name required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.selected_categories.length == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Service categories required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.no_of_branches == null || this.current_merchant.no_of_branches == '' || this.current_merchant.no_of_branches == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No. of branches required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.selected_emirate == null || this.current_merchant.selected_emirate == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Emirate required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.address == null || this.current_merchant.address == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Address required!', life: 3000 });
                return false;
            }
            this.save_merchant_loader = true;
            axios.post(this.api_url+'superadmin/update-merchant-data',
            this.current_merchant,
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token'),
                'Content-Type': 'multipart/form-data',
            }
            }).then(response => {
                if (response.data.data.status) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.data.message, life: 3000 });
                }
                this.save_merchant_loader = false
            });
        },
        confirm_validate_and_save() {
            axios.post(this.api_url+'superadmin/update-merchant-data',
            this.current_merchant,
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
            }
            }).then(response => {
                if (response.data.data.status) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
                    this.send_welcome_email_to_merchant();
                    this.validate_and_save_modal = false;
                    this.$emit('hide_merchant_detail', 'listing-page');
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.data.message, life: 3000 });
                }
            });
        },
        validate_and_save() {
            
            if (this.current_merchant.email == null || this.current_merchant.email == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact email required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.mobile == null || this.current_merchant.mobile == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact phone required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.brand_name == null || this.current_merchant.brand_name == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Brand name required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.selected_categories.length == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Service categories required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.no_of_branches == null || this.current_merchant.no_of_branches == '' || this.current_merchant.no_of_branches == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No. of branches required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.selected_emirate == null || this.current_merchant.selected_emirate == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Emirate required!', life: 3000 });
                return false;
            }
            if (this.current_merchant.address == null || this.current_merchant.address == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Address required!', life: 3000 });
                return false;
            }
            
            this.validate_and_save_modal = true;
        },
        download_pdf() {
            fetch(this.current_merchant.trade_license)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob(); // Get the response as a blob
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob); // Create a URL for the blob
                    const a = document.createElement('a'); // Create an anchor element
                    a.href = url;
                    a.download = 'trade_license_'+this.current_merchant.merchant_id+'.pdf'; // Set the filename
                    document.body.appendChild(a); // Append anchor to body
                    a.click(); // Programmatically click the anchor to trigger the download
                    a.remove(); // Remove the anchor from the document
                    window.URL.revokeObjectURL(url); // Release the blob URL
                })
                .catch(error => {
                    console.error('Error downloading the PDF:', error);
                });
        }
    },
    mounted(){
        this.current_merchant.merchant_id = this.merchant.id;
        this.current_merchant.user_id = this.merchant.user_id;
        this.current_merchant.email = this.merchant.email;
        this.current_merchant.brand_name = this.merchant.brand_name;
        this.current_merchant.mobile = this.merchant.phone;
        this.current_merchant.address = this.merchant.address;
        this.current_merchant.no_of_branches = this.merchant.no_of_branches;
        this.current_merchant.trade_license = this.merchant.trade_license;
        this.current_merchant.selected_categories = this.merchant.service_categories;
        this.current_merchant.selected_emirate = this.emirates.find(item => item.id === this.merchant.city_id);
        var result = this.current_merchant.selected_categories.map(item => {
            return this.merchant_categories.find(category => category.id === item.merchant_category_id);
        });
        this.current_merchant.selected_categories = result;
        this.pdfUrl = this.current_merchant.trade_license;
        this.generateThumbnail();
        
    }
};
</script>


<style>

</style>
  