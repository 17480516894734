<template>

    <sidebarNav />

    <div class="page-header add-merchant-header">
      <div class="header-left-area">
        <router-link to="/merchants">
          <i class="pi pi-arrow-left"></i>
        </router-link>
        <h1 class="page-title m-0">Add Merchants</h1>
      </div>
      
      <div class="header-right-area">
      </div>
    </div>

    <div class="app-content content add-merchant-container">
        <div class="container-fuild listing-container">
            <div class="grid m-0">
                <div class="col-12 md:col-6 lg:col-6 ps-0">
                    <div class="card-border-box">
                        <h2 class="listing-title mb-3">Contact Details</h2>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/sms.svg">
                                <inputText-prime class="form-input-fields" type="email" placeholder="Enter Your Email" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-4">
                            <span class="p-input-icon-left">
                                <vue-tel-input v-model="signup_phone" mode="international" placeholder="Write your mobile number"></vue-tel-input>
                            </span>
                        </div>
                    </div>

                    <div class="card-border-box">
                        <h2 class="listing-title mb-3">Brand Details</h2>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/name.svg">
                                <inputText-prime class="form-input-fields" placeholder="Enter Brand Name" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/category.svg">
                                <multiSelect-prime v-model="selectedCategory" :options="categories" optionLabel="name" filter placeholder="Select your Services Category " :maxSelectedLabels="5" class="w-full" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/category.svg">
                                <inputText-prime type="number" class="form-input-fields" placeholder="No of Branches" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                <inputText-prime class="form-input-fields" placeholder="Emirate" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                <inputText-prime class="form-input-fields" placeholder="Address" />
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-6 pe-0">
                    <div class="card-border-box">
                        <h2 class="listing-title mb-3">Trade License</h2>

                        <div class="form-group">
                            <div class="upload-image-box upload-document-box">
                                <div class="upload-icon">
                                    <img class="" src="../../assets/images/uplolad-doc.svg">
                                </div>
                                <p class="upload-box-label">Drop your file here. or <span>Browse</span></p>
                            </div>
                            
                            <div class="upload-image-box upload-document-box uploaded-bg hidden">
                                <img class="" src="../../assets/images/license-doc.png">
                            </div>
                        </div>
                    </div>

                    <div class="card-border-box mt-4">
                        <h2 class="listing-title mb-3">Expiry Date</h2>

                        <div class="form-group">
                            <DatePicker-prime v-model="date" placeholder="DD/MM/YYYY" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid m-0 mt-2">
                <div class="col-12 md:col-6 lg:col-6 ps-0">
                </div>

                <div class="col-12 md:col-6 lg:col-6 ps-0 gap-2 flex justify-content-end">
                    <button-prime class="wafa-primary-btn add-marchat-btn" label="Add Merchant" @click="confirmationVisible = true" />
                </div>
            </div>
        </div>
    </div>

    <!-- Confirmation Modal -->
    <dialog-prime class="confirmation-plan-modal" v-model:visible="confirmationVisible" modal header="New Program" :style="{ width: '32rem' }">
    
        <div class="modal-body-container">
            <div class="confirmation-info">
                <h2>Confirm Registration</h2>
                <p>Are you sure that the information you provided is correct and you want to proceed onboarding the merchant by sending email</p>
            </div>
        </div>

        <div class="confirmation-footer-btn">
            <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="Confirm"></button-prime>
        </div>
    </dialog-prime>
    <!-- / Confirmation Modal -->

</template>

<script setup>
import { ref } from "vue";

const confirmationVisible = ref(false);

const date = ref();
const selectedCategory = ref();
const categories = ref([
    { name: 'Food' },
    { name: 'Beauty & Spa' },
    { name: 'Fitness' },
    { name: 'Clinical'},
    { name: 'Shopping' }
]);

</script>
  
<script>
import sidebarNav from '../sharedViews/sharedView.vue';

export default {
  components: {
    sidebarNav
  }
}
</script>

<style>

</style>
  