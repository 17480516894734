<template>

    <sidebarNav />

    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Merchants</h1>
      </div>
      
      <div class="header-right-area">
        <a :href="wafa_app_url + '/register'" target="_blank">
            <button-prime class="new-program" label="Add Merchant" icon="pi pi-plus"  />
        </a>
      </div>
    </div>

    <!-- Merchant Details -->
    <merchantDetails v-if="page_status=='merchant-detail-page'" :merchant="merchant" @hide_merchant_detail="handleUpdate" :emirates="emirates" :merchant_categories="merchant_categories" />
    <!-- Merchant Details -->

    
     <!-- active Merchant Details -->
     <activeMerchantDetails v-else-if="page_status=='active-merchant-detail-page'" :merchant_id="merchant_id_for_detail" :merchant_categories="merchant_categories" @show_merchant_edit_page="show_merchant_edit_page" @hide_active_merchant_detail="handleUpdate"/>
    <!-- active Merchant Details -->

    <div class="app-content content merchant-app-content" v-else>
      <div class="container-fuild p-0">
        <div class="grid">
          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/merchant.svg">
              </div>
              <p>All Merchants</p>
              <h2>{{ count_merchants != null ? count_merchants.all_merchants  : 0}}</h2>
            </div> 
          </div>

          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/merchant.svg">
              </div>
              <p>New Merchants</p>
              <h2>{{ count_merchants != null ? count_merchants.new_merchants  : 0}}</h2>
            </div>
          </div>

          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/dashboard-icons/user.svg">
              </div>
              <p>Pending Merchant Requests</p>
              <h2>{{ count_merchants != null ? count_merchants.pending_merchants  : 0}}</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fuild listing-container merchant-category-container">
        <div class="user-activity-inner mt-1">
            <div class="grid m-0">
                <div class="col-12 md:col-6 lg:col-6 ps-0 pe-0 category-listing-contanier">
                    <div class="card-border-box">
                        <span class="customer-header-filter">
                            <h2>Categories</h2>
                        </span>

                        <div id="users-list" class="user-list-wrapper list-group ps">
                            <ul class="users-list list media-list">
                                <li :class="{ active: activeItem === 'all' }" @click="setActiveItem('all')">
                                    <span class="avatar">
                                        <img height="42" width="42" alt="Generic placeholder image" src="../../assets/images/category-icons/1.png">
                                    </span>
                                    <div class="user-info flex-grow-1">
                                        <h5 class="mb-0">All</h5>
                                    </div>
                                </li>
                                <li v-for="item in merchant_categories" :key="item" :class="{ active: item === activeItem }" 
                                @click="setActiveItem(item)">
                                    <span class="avatar">
                                        <img height="42" width="42" alt="Generic placeholder image" :src="item.web_app_image">
                                    </span>
                                    <div class="user-info flex-grow-1">
                                        <h5 class="mb-0">{{ item.name }}</h5>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-6 ps-0 pe-0 merchant-tab-listing-container">
                    <div class="card-border-box">
                        <Tabs-prime :value="merchant_list_type">
                            <TabList-prime class="activity-timeline-inner-tabs">
                                <Tab-prime value="all" @click="set_tabs('all')">All</Tab-prime>
                                <Tab-prime value="license-rejected" @click="set_tabs('license-rejected')">License Conflict</Tab-prime>
                                <Tab-prime value="pending-request" @click="set_tabs('pending-request')">Pending Requests</Tab-prime>

                                <span class="offer-header-filters timeline-history-filter">
                                    <button-prime class="offer-filter-btn" label="Filter by date" icon="pi pi-sliders-h" />
                                </span>
                            </TabList-prime>
            
                            <TabPanels-prime>
                                <TabPanel-prime value="all">
                                    <div class="table-listing" v-if="merchant_list_type=='all'">
                                        <DataTable-prime :globalFilterFields="['brand_name', 'email', 'service_categories', 'status']" v-model:filters="filters" :value="merchants" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem">
                                            <template #header>
                                                <div class="flex justify-end">
                                                    <IconField-prime>
                                                        <InputIcon-prime>
                                                            <i class="pi pi-search"></i>
                                                        </InputIcon-prime>
                                                        <inputText-prime v-model="filters['global'].value" placeholder="Keyword Search" />
                                                    </IconField-prime>
                                                </div>
                                            </template>
                                            <template #empty> No data found. </template>
                                            <template #loading> Loading data. Please wait. </template>
                                            <Column-prime header="Brand">
                                                <template #body="slotProps">
                                                    <div class="user">
                                                        <!-- <img src="https://xsgames.co/randomusers/avatar.php?g=male" /> -->
                                                        <div class="user-name">
                                                            <h2>{{ slotProps.data.brand_name }}</h2>
                                                            <p>{{ slotProps.data.email }}</p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Category">
                                                <template #body="slotProps">
                                                    <div class="table-listing-badge">
                                                        <badge-prime class="listing-badge" v-for="category in find_category(slotProps.data)" :key="category">
                                                        {{ category.name }}
                                                        </badge-prime>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Status">
                                                <template #body="slotProps">
                                                    <div class="table-listing-badge">
                                                        <span :class="{
                                                            'u-color-red': slotProps.data.status === 'inactive',
                                                            'u-color-red': slotProps.data.status === 'license-rejected',
                                                            'u-color-green': slotProps.data.status === 'active',
                                                            'u-color-gray': slotProps.data.status === 'pending-request',
                                                            'u-color-gray': slotProps.data.status === 'trashed'
                                                        }"> {{ slotProps.data.status }} </span>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Loyalty">
                                                <template #body="slotProps">
                                                    <div class="table-listing-badge">
                                                        <badge-prime class="listing-badge"> {{ slotProps.data.count_program_count }} Programs </badge-prime>
                                                        <badge-prime class="listing-badge"> {{ slotProps.data.count_reward_count }} Rewards </badge-prime>
                                                        <badge-prime class="listing-badge"> {{ slotProps.data.count_offer_count }} offer </badge-prime>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Customers">
                                                 <template #body="slotProps">
                                                    {{ slotProps.data.total_customers }}
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Joining Date">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.joining_date }}
                                                </template>
                                            </Column-prime>
                                            <Column-prime>
                                                <template #body="slotProps">
                                                    <div class="action-td action-overlay">
                                                        <div class="action-btn-dots">
                                                        <button-prime type="button" icon="pi pi-chevron-down" @click="toggleMenu(slotProps.data.id)" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" />
                                                        
                                                        <div id="overlay_menu_dropdown" :class="'p-menu p-component p-menu-overlay action-menu-list action-menu-list-table menu-'+slotProps.data.id">
                                                            <ul id="overlay_menu_list" class="p-menu-list">
                                                                <li id="" class="p-menu-item" role="menuitem" @click="active_merchant_detail(slotProps.data)">
                                                                    <div class="p-menu-item-content">
                                                                        <a class="p-menu-item-link" aria-hidden="true">
                                                                        <span class="p-menu-item-label">Detail</span>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                <li id="" class="p-menu-item" role="menuitem" @click="edit_merchant(slotProps.data)">
                                                                    <div class="p-menu-item-content">
                                                                        <a class="p-menu-item-link" aria-hidden="true">
                                                                        <span class="p-menu-item-label">Edit</span>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                <li id="" class="p-menu-item" role="menuitem" @click="update_merchant(slotProps.data.id, 'inactive')">
                                                                    <div class="p-menu-item-content">
                                                                        <a class="p-menu-item-link" aria-hidden="true">
                                                                        <span class="p-menu-item-label">Disable</span>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                <li id="" class="p-menu-item" role="menuitem" @click="update_merchant(slotProps.data.id, 'trashed')">
                                                                    <div class="p-menu-item-content">
                                                                        <a class="p-menu-item-link" aria-hidden="true">
                                                                        <span class="p-menu-item-label">Delete</span>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                        </DataTable-prime>
                                    </div>

                                   
                                </TabPanel-prime>
            
                                <TabPanel-prime value="license-rejected">
                                    <div class="table-listing" v-if="merchant_list_type=='license-rejected'">
                                        <DataTable-prime :globalFilterFields="['brand_name', 'email', 'service_categories', 'status']" v-model:filters="filters" :value="merchants" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem">
                                            <template #header>
                                                <div class="flex justify-end">
                                                    <IconField-prime>
                                                        <InputIcon-prime>
                                                            <i class="pi pi-search"></i>
                                                        </InputIcon-prime>
                                                        <inputText-prime v-model="filters['global'].value" placeholder="Keyword Search" />
                                                    </IconField-prime>
                                                </div>
                                            </template>
                                            <template #empty> No data found. </template>
                                            <template #loading> Loading data. Please wait. </template>
                                            <Column-prime header="Brand">
                                                <template #body="slotProps">
                                                    <div class="user">
                                                        <!-- <img src="https://xsgames.co/randomusers/avatar.php?g=male" /> -->
                                                        <div class="user-name">
                                                            <h2>{{ slotProps.data.brand_name }}</h2>
                                                            <p>{{ slotProps.data.email }}</p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Category">
                                                <template #body="slotProps">
                                                    <div class="table-listing-badge">
                                                        <badge-prime class="listing-badge" v-for="category in find_category(slotProps.data)" :key="category">
                                                        {{ category.name }}
                                                        </badge-prime>
                                                    </div>
                                                </template>
                                            ]</Column-prime>
                                            <Column-prime header="Status">
                                                <template #body="slotProps">
                                                    <span class="red"> {{ slotProps.data.status }} </span>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Joining Date">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.joining_date }}
                                                </template>
                                            </Column-prime>
                                            <Column-prime>
                                                <template #body="slotProps">
                                                    <div class="action-td action-overlay">
                                                        <div class="action-btn-dots">
                                                        <button-prime type="button" icon="pi pi-chevron-down" @click="toggleMenu(slotProps.data.id)" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" />
                                                        
                                                        <div id="overlay_menu_dropdown" :class="'p-menu p-component p-menu-overlay action-menu-list action-menu-list-table menu-'+slotProps.data.id">
                                                            <ul id="overlay_menu_list" class="p-menu-list">
                                                                <li id="" class="p-menu-item" role="menuitem" @click="edit_merchant(slotProps.data)">
                                                                    <div class="p-menu-item-content">
                                                                        <a class="p-menu-item-link" aria-hidden="true">
                                                                        <span class="p-menu-item-label">Edit</span>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                
                                                                <li id="" class="p-menu-item" role="menuitem" @click="update_merchant(slotProps.data.id, 'trashed')">
                                                                    <div class="p-menu-item-content">
                                                                        <a class="p-menu-item-link" aria-hidden="true">
                                                                        <span class="p-menu-item-label">Delete</span>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                        </DataTable-prime>
                                    </div>

                                    
                                </TabPanel-prime>

                                <TabPanel-prime value="pending-request">
                                    <div class="table-listing" v-if="merchant_list_type=='pending-request'">
                                        <DataTable-prime :globalFilterFields="['brand_name', 'email', 'phone', 'city.name', 'service_categories']" v-model:filters="filters" :value="merchants" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem">
                                            <template #header>
                                                <div class="flex justify-end">
                                                    <IconField-prime>
                                                        <InputIcon-prime>
                                                            <i class="pi pi-search"></i>
                                                        </InputIcon-prime>
                                                        <inputText-prime v-model="filters['global'].value" placeholder="Keyword Search" />
                                                    </IconField-prime>
                                                </div>
                                            </template>
                                            <template #empty> No data found. </template>
                                            <template #loading> Loading data. Please wait. </template>
                                            <Column-prime header="Brand">
                                                <template #body="slotProps">
                                                    <div class="user">
                                                        <!-- <img src="https://xsgames.co/randomusers/avatar.php?g=male" /> -->
                                                        <div class="user-name">
                                                            <h2>{{ slotProps.data.brand_name }}</h2>
                                                            <p>{{ slotProps.data.email }}</p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Category">
                                                <template #body="slotProps">
                                                    <div class="table-listing-badge">
                                                        <badge-prime class="listing-badge" v-for="category in find_category(slotProps.data)" :key="category">
                                                        {{ category.name }}
                                                        </badge-prime>
                                                    </div>
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Contact">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.phone }}
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Emirate">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.city.name }}
                                                </template>
                                            </Column-prime>
                                            <Column-prime header="Applied Date">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.joining_date }}
                                                </template>
                                            </Column-prime>
                                            <Column-prime>
                                                <template #body="slotProps">
                                                    <span class="u-color-teal cursor-pointer" @click="set_detail_page(slotProps.data)">respond</span>
                                                </template>
                                            </Column-prime>
                                        </DataTable-prime>
                                    </div>

                                    
                                </TabPanel-prime>
                            </TabPanels-prime>
                        </Tabs-prime>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <toast-prime />
</template>
  
  
<script>
import sidebarNav from '../sharedViews/sharedView.vue';
import merchantDetails from './merchantDetails.vue'
import activeMerchantDetails from './activeMerchantDetails.vue'
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import axios from 'axios'
// import moment from 'moment' 
import $ from 'jquery'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FilterMatchMode } from '@primevue/core/api';
  
  export default {
    components: {
        sidebarNav,
        merchantDetails,
        activeMerchantDetails
    },
    data() {
      return {
        activeItem: 'all',  // Tracks the currently active item,
        activeMenu : null,
        merchant_list_type : 'all',
        modules: [Navigation, Pagination, Autoplay, Scrollbar,],
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS }
        },
        selected_category : null,
        page_status : 'listing-page',
        merchants : null,
        merchant_categories : [],
        emirates : [],
        merchant : {},
        merchant_id_for_detail : 0,
        count_merchants : null
      }
    },
    mounted() {
        this.count_merhants();
        this.get_emirates();
        this.get_merchant_categories();
        this.get_all_merhants();
    },
    methods: {
        toggleMenu(merchantId) {
        // // Toggle visibility of the menu for the specific merchant
        // if (this.activeMenu === merchantId) {
        //     this.activeMenu = null;
        // } else {
        //     this.activeMenu = merchantId;
        // }
            $('.p-menu').css('display', 'none');
            $('.menu-'+merchantId).show();
        },

        set_tabs(data) {
            this.merchant_list_type = data;
            this.merchants = null;
            this.get_all_merhants();
        },
        setActiveItem(item) {
            this.activeItem = item;  // Set clicked item as active
            this.selected_category = item;
            this.get_all_merhants();
        },
        handleUpdate(data) {
            // this.merchant_list_type = 'pending-request';
            this.page_status = data;
            this.get_all_merhants();
        },
        show_merchant_edit_page(data) {
            this.page_status = data;
        },
        find_category(data){
            
            var idsInArray2 = data.service_categories.map(item => item.merchant_category_id);
            var matchingArray = this.merchant_categories.filter(item => idsInArray2.includes(item.id));
            return matchingArray;

        },
        set_detail_page(data) {
            this.merchant = data;
            this.merchant.from = 'respond';
            this.page_status = 'merchant-detail-page';
        },
        on_merchants_page_change(event) {
            this.merchants_current_page = event.page;
        },
        get_emirates() {
            axios.post(this.api_url+'get-emirates').then(response => {
                this.emirates = response.data.data;
            });
        },
        get_merchant_categories() {
            axios.post(this.api_url+'get-merchant-categories').then(response => {
                this.merchant_categories = response.data.data;
            });
        },
        get_all_merhants() {
            axios.post(this.api_url+'superadmin/get-all-merchants',
            {type : this.merchant_list_type, category : this.selected_category},
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
            }
            }).then(response => {
                this.merchants = response.data.data;
            });
        },
        count_merhants() {
            axios.post(this.api_url+'superadmin/count-merchants',
            {},
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
            }
            }).then(response => {
                this.count_merchants = response.data.data;
            });
        },
        active_merchant_detail(merchant){
            
            this.merchant = merchant;
            this.merchant.from = 'edit';
            this.merchant_id_for_detail = merchant.id;
            this.page_status = 'active-merchant-detail-page';
            
        },
        update_merchant(merchant_id, status) {
            axios.post(this.api_url+'superadmin/update-merchant-data',
            {
                status : status,
                merchant_id : merchant_id,
                set_user_inactive : status == 'inactive' || status == 'trashed' ? 1 : 0
            },
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
            }
            }).then(response => {
                if (response.data.data.status) {
                    this.reject_license_button_loader = false;
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
                    this.get_all_merhants();
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.data.message, life: 3000 });
                }
            });
        },
        edit_merchant(merchant){
            this.merchant = merchant;
            this.merchant.from = 'edit';
            this.page_status = 'merchant-detail-page';

        }
    } 
  }
  </script>
  
  <style>
  
  </style>
  