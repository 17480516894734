<template>

    <sidebarNav />

    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Good Day Admin!</h1>
      </div>
      
      <div class="header-right-area">
        <div class="user-profile-img-circle">
          <img src="../../assets/images/default.png">
        </div>
      </div>
    </div>

    <div class="dashboard-top-info-header"></div>

    <div class="app-content dashboard-app-content content">
      <div class="container-fuild">
        <div class="grid m-0">
          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/merchant.svg">
              </div>
              <p>Total Merchants</p>
              <h2>{{ total_merchants }}</h2>
            </div> 
          </div>

          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/dashboard-icons/user.svg">
              </div>
              <p>Total Customers</p>
              <h2>{{ total_customers }}</h2>
            </div>
          </div>
        </div>
        
        <div class="grid m-0">
          <div class="col-12 md:col-8 lg:col-8 pt-0 card-column-pr">
            <div class="card-inner-container">
              <div class="dashboard-card-header">
                <h2 class="dashboard-cards-title">Existing Merchants</h2>

                <badge-prime class="total-record-badge">
                  Total: {{ active_merchants != null ? active_merchants.length : 0 }}
                </badge-prime>
              </div>

              <div class="table-listing">
                <DataTable-prime :globalFilterFields="['brand_name', 'email', 'service_categories']" v-model:filters="filters" :value="active_merchants" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]">
                  <template #header>
                      <div class="flex justify-end">
                          <IconField-prime>
                              <InputIcon-prime>
                                  <i class="pi pi-search"></i>
                              </InputIcon-prime>
                              <inputText-prime v-model="filters['global'].value" placeholder="Keyword Search" />
                          </IconField-prime>
                      </div>
                  </template>
                  <template #empty> No data found. </template>
                  <template #loading> Loading data. Please wait. </template>
                  <Column-prime header="Brand">
                        <template #body="slotProps">
                            <div class="user">
                                <div class="user-name">
                                    <h2>{{ slotProps.data.brand_name }}</h2>
                                    <p>{{ slotProps.data.email }}</p>
                                </div>
                            </div>
                        </template>
                    </Column-prime>
                    <Column-prime header="Category">
                      <template #body="slotProps">
                          <div class="table-listing-badge">
                              <badge-prime class="listing-badge" v-for="category in find_category(slotProps.data)" :key="category">
                              {{ category.name }}
                              </badge-prime>
                          </div>
                      </template>
                    </Column-prime>
                    <Column-prime header="Programs">
                        <template #body="slotProps">
                           {{ slotProps.data.count_program_count }}
                        </template>
                    </Column-prime>
                    <Column-prime header="Joining Date">
                        <template #body="slotProps">
                          {{ slotProps.data.joining_date }}
                        </template>
                    </Column-prime>
                </DataTable-prime>
              </div>

              <!-- <div class="pagination-container">
                <paginator-prime :rows="5" :totalRecords="25"></paginator-prime>
              </div> -->
            </div>
          </div>

          <div class="col-12 md:col-4 lg:col-4 pt-0 card-column-pl">
            <div class="card-inner-container">
              <div class="dashboard-card-header">
                <h2 class="dashboard-cards-title">Pending Merchant Requests</h2>
                <label>{{ pending_merchants != null ? pending_merchants.length : 0 }}</label>
              </div>
              
              <div class="pending-merchant-listing">
                <ul class="pending-merchant-listing">
                  <li class="merchant-listing-item" v-for="item in pending_merchants" :key="item">
                    <h2>{{ item.brand_name }}</h2>
                    <p>Applied Date: {{ item.joining_date }}</p>
                    <i class="pi pi-chevron-right"></i>
                  </li>

                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
  
<script>
import sidebarNav from '../sharedViews/sharedView.vue';
import axios from 'axios'
import { FilterMatchMode } from '@primevue/core/api';
// import moment from 'moment'
export default {
  components: {
    sidebarNav,
  },
  data(){
    return {
      total_merchants : 0,
      total_customers : 0,
      active_merchants : null,
      pending_merchants : null,
      merchant_categories : [],
      filters: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
    }
  },
  mounted() {
    this.get_merchant_categories();
    this.get_dashboard_data();
    this.get_active_merchants();
    this.get_pending_merchants();
  },
  methods : {
    get_merchant_categories() {
        axios.post(this.api_url+'get-merchant-categories').then(response => {
            this.merchant_categories = response.data.data;
        });
    },
    find_category(data){
            
        var idsInArray2 = data.service_categories.map(item => item.merchant_category_id);
        var matchingArray = this.merchant_categories.filter(item => idsInArray2.includes(item.id));
        return matchingArray;

    },
    get_dashboard_data(){
      axios.post(this.api_url+'superadmin/get-dashboard-data',
        {},
        {
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
        }
        }).then(response => {
            this.total_merchants = response.data.data.total_merchants;
            this.total_customers = response.data.data.total_customers;
        });
    },
    get_active_merchants() {
        axios.post(this.api_url+'superadmin/get-merchants',
        {status : 'active'},
        {
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
        }
        }).then(response => {
            
            this.active_merchants = response.data.data;
    
        });
    },
    get_pending_merchants() {
        axios.post(this.api_url+'superadmin/get-merchants',
        {status : 'pending-request'},
        {
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
        }
        }).then(response => {

          this.pending_merchants = response.data.data;

        });
    },
  }
}
</script>

<style>

</style>
  