<template>



    <div class="page-header merchant-detail-header">
      <div class="header-left-area">
        <i @click="$emit('hide_active_merchant_detail', 'listing-page')" class="pi pi-arrow-left"></i>
        <h1 class="page-title m-0">Merchant Details</h1>
      </div>
      
      <div class="header-right-area">
        <button-prime class="wafa-primary-outline-btn" @click="$emit('show_merchant_edit_page', 'merchant-detail-page')" label="Edit" icon="pi pi-pencil" />
        <button-prime class="wafa-primary-outline-btn" @click="confirm_dialogue=true; merchant_status='inactive'" label="Restrict" icon="pi pi-ban" />
        <button-prime class="wafa-primary-outline-btn delete-btn" @click="confirm_dialogue=true; merchant_status='trashed'" label="Delete" icon="pi pi-trash" />
      </div>
    </div>

    <div class="app-content content">
      <div class="container-fuild listing-container merchant-detail-container">
        <div class="merchant-detail-left-area">
          <div class="merchant-deail-banner" v-bind:style="{'background-image': 'url('+merchant.cover_photo+')'}"></div>
          <div class="merchant-logo">
            <img class="merchant-logo-img" :src="merchant.logo_photo">
          </div>

          <div class="merchant-basic-info">
            <h2 class="merchant-deatil-name">{{ merchant.brand_name }}</h2>
            <p class="merchant-address">{{ merchant.address }}<span v-if="merchant.city!=null">, {{ merchant.city.name }}</span></p>
          </div>

          <div class="merchant-more-info">
            <ul>
              <li>
                <span class="info-label">phone number</span>
                <span class="value-label">{{ merchant.mobile }}</span>
              </li>
              <li>
                <span class="info-label">email</span>
                <span class="value-label">{{ merchant.email }}</span>
              </li>
              <li>
                <span class="info-label">emirate</span>
                <span class="value-label"><span v-if="merchant.city!=null">{{ merchant.city.name }}</span></span>
              </li>
              <li>
                <span class="info-label">No of Branches</span>
                <span class="value-label">{{ merchant.no_of_branches }}</span>
              </li>
              <li>
                <span class="info-label">License Expiry</span>
                <span class="value-label">-</span>
              </li>
              <li>
                <span class="info-label">Joining Date</span>
                <span class="value-label">{{ merchant.created_at }}</span>
              </li>
            </ul>
          </div>

          <div class="merchant-detail-category-list">
            <h4>Category</h4>
            <ul>
              <template v-if="('service_categories' in merchant)">
                <li v-for="category in find_category(merchant)" :key="category">
                  <div class="category-icon">
                    <img alt="" :src="category.web_app_image">
                  </div>
                  <div class="category-title">{{ category.name }}</div>
                </li>
              </template>
            </ul>
          </div>

          <div class="trade-license-container">
            <h4>Trade Licens</h4>
            <div class="trade-license-img">
              <canvas ref="pdfCanvas" style="display: none;"></canvas>
              <img v-if="thumbnail" :src="thumbnail" alt="PDF Thumbnail" />
              <span v-else>License not found!</span>
            </div>
          </div>
        </div>

        <div class="merchant-detail-right-area">
          <!-- Active Programs listing -->
          <!-- <div class="merchant-program-listing-inner">
            <h2 class="listing-title">Active Programs</h2>

            <div class="listing-item-container">
              <div class="listing-item-box">
                <div class="flex-list-item">
                  <span class="type-badge-program">
                    stamp based
                  </span>

                  <div class="created-date-action-btn">
                    <span>Created</span> <span>10/10/2023</span>
                  </div>
                </div>

                <div class="flex-list-item">
                  <span class="flex-list-text flex-text-grey">
                    Stamps on each scan 
                  </span>

                  <span class="flex-list-text">
                    02
                  </span>
                </div>

                <div class="flex-list-item">
                  <span class="flex-list-text flex-text-grey">
                    No of Rewards 
                  </span>

                  <span class="flex-list-text">
                    02
                  </span>
                </div>
              </div>
            </div>
          </div> -->
          <!-- / Active Programs listing -->

          <!-- Program Rewards listing -->
          <div class="merchant-program-listing-inner">
            <h2 class="listing-title">Active Rewards</h2>
   
            <div class="listing-item-container listing-item-all-container" v-if="!merchant.rewards">
                  <div class="listing-box-loaders" v-for="index in 2" :key="index">
                      <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                      <div class="flex justify-between mt-3 mb-3">
                          <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                          <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                      </div>
                      <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                      <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                      <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                  </div>
              </div>

              <div class="listing-item-container listing-item-all-container" v-else-if="merchant.rewards.length==0">
                  <div class="not-found-data">
                      <img src="../../assets/images/dummy-data-img.svg">
                      <h2>No rewards available</h2>
                  </div>
              </div>

            <template v-else>
                <div class="listing-item-container listing-item-all-container">
                    <div class="listing-item-box" v-for="(item, index) in paginated_rewards" :key="index">
                        <div class="flex-list-item reward-img-area" v-bind:style="{'background-image': 'url('+item.image+')'}">
                            <!-- <div class="action-btn-dots">
                                <button-prime type="button" icon="pi pi-ellipsis-v" @click="toggle(index, $event)" aria-haspopup="true" aria-controls="overlay_menu" />
                                <menu-prime ref="menu" id="overlay_menu" class="action-menu-list" :model="item.menuItems" :popup="true" />
                            </div> -->
                        </div>

                        <div class="flex-list-item">
                            <span class="reward-title-name">
                                {{ item.name }}
                            </span>
                        </div>
                        
                        <div class="flex-list-item justify-content-start gap-2">
                            <span class="type-badge-program reward-badge-yellow">
                                {{ item.reward_type.name }}
                            </span>

                            <span class="type-badge-program reward-badge-green">
                                {{ item.item_name }}
                            </span>
                        </div>

                        <div class="hr-line">
                            <hr />
                        </div>

                        <div class="flex-list-item">
                            <span class="flex-list-text flex-text-grey">
                                Value of Item
                            </span>

                            <span class="flex-list-text">
                                {{ item.item_value }} AED
                            </span>
                        </div>

                        <div class="flex-list-item">
                            <span class="flex-list-text flex-text-grey">
                                Stamps Required
                            </span>

                            <span class="flex-list-text">
                                {{ item.no_of_stamps_target }}
                            </span>
                        </div>
                        
                        <div class="flex-list-item">
                            <span class="flex-list-text flex-text-grey">
                                Expires
                            </span>

                            <span class="flex-list-text">
                                {{ item.expiry_date }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="pagination-container">
                    <paginator-prime v-if="merchant.rewards.length>3" :rows="rewards_rows_per_page" :totalRecords="merchant.rewards.length" :page="rewards_current_page" @page="on_rewards_page_change"></paginator-prime>
                </div>
            </template>
          </div>
          <!-- / Program Rewards listing -->
          
          <!-- Active offers listing -->
          <div class="merchant-program-listing-inner">
            <h2 class="listing-title">Active Offers</h2>

            <div class="listing-item-container listing-item-all-container" v-if="!merchant.offers">
                      <div class="listing-box-loaders" v-for="index in 3" :key="index">
                            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                            <div class="flex justify-between mt-3 mb-3">
                                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                            </div>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                        </div>
                    </div>

                    <div class="listing-item-container listing-item-all-container" v-else-if="merchant.offers.length==0">
                      <div class="not-found-data">
                          <img src="../../assets/images/dummy-data-img.svg">
                          <h2>No offer available</h2>
                      </div>
                    </div>

                    <template v-else>
                    <div class="listing-item-container listing-item-all-container">
                        <div class="listing-item-box" v-for="(item, index) in paginated_offers" :key="index">
                            <div class="flex-list-item offer-img-area" v-bind:style="{'background-image': 'url('+item.image+')'}">
                                <div class="action-btn-dots">
                                    <button-prime type="button" icon="pi pi-ellipsis-v" @click="toggle(index, $event)" aria-haspopup="true" aria-controls="overlay_menu" />
                                    <menu-prime ref="menu" id="overlay_menu" class="action-menu-list" :model="item.menuItems" :popup="true" />
                                </div>
                            </div>

                            <div class="flex-list-item">
                                <span class="offer-title-name">
                                  {{ item.name }}
                                </span>
                            </div>
                            
                            <div class="flex-list-item justify-content-start gap-2">
                                <span class="type-badge-program offer-badge-green">
                                  {{ item.offer_days[0].start_time }} to {{ item.offer_days[0].end_time }}
                                </span>
                            </div>

                            <div class="hr-line">
                                <hr />
                            </div>

                            <div class="flex-list-item">
                                <span class="flex-list-text flex-text-grey">
                                  Redeems per user
                                </span>

                                <span class="flex-list-text">
                                    {{ item.allow_per_user }}
                                </span>
                            </div>

                            <div class="flex-list-item">
                                <span class="flex-list-text flex-text-grey">
                                  Total Redeems
                                </span>

                                <span class="flex-list-text">
                                    -
                                </span>
                            </div>
                            
                            <div class="flex-list-item">
                                <span class="flex-list-text flex-text-grey">
                                    Expires
                                </span>

                                <span class="flex-list-text">
                                    {{ item.expiry_date }}
                                </span>
                            </div>
                        </div>
                    </div>

                      <div class="pagination-container">
                          <!-- <paginator-prime :rows="5" :totalRecords="25"></paginator-prime> -->
                          <paginator-prime v-if="merchant.offers.length>3" :rows="offers_rows_per_page" :totalRecords="merchant.offers.length" :page="offers_current_page" @page="on_offers_page_change"></paginator-prime>
                          
                      </div>
                    </template>
          </div>
          <!-- / Active Offers listing -->
        </div>
      </div>
    </div>
    <!-- Confirmation Modal -->
    <dialog-prime class="confirmation-plan-modal" v-model:visible="confirm_dialogue" modal header="Alert" :style="{ width: '32rem' }">
    
    <div class="modal-body-container">
        <div class="confirmation-info w-full">
            <h2>Confirm Action</h2>
            <p>By continuing you understand that this will update merchant's status,  merchant's privileges will be revived.</p>
        </div>
    </div>

    <div class="confirmation-footer-btn">
        <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="Confirm" :loading=confirm_loader @click="update_merchant"></button-prime>

        
    </div>
</dialog-prime>
<!-- / Confirmation Modal -->
</template>

  
<script>
import axios from 'axios'
import moment from 'moment'
// import $ from 'jquery'
import * as pdfjsLib from 'pdfjs-dist/webpack'; // Make sure this is correctly installed and set up


export default {

  props: {
    merchant_id: Number,
    merchant_categories : Array
  },
  data() {
    return {
      merchant : {},
      pdfUrl: null,
      thumbnail: null,
      rewards_rows_per_page : 2,
      rewards_current_page : 0,
      offers_rows_per_page : 2,
      offers_current_page : 0,
      confirm_dialogue : false,
      merchant_status : null,
      confirm_loader : false
    }
  },
  computed: {
    paginated_rewards() {
      if (!this.merchant.rewards) return [];
      const start = this.rewards_current_page * this.rewards_rows_per_page;
      const end = start + this.rewards_rows_per_page;
      return this.merchant.rewards.slice(start, end);
    },
    paginated_offers() {
      if (!this.merchant.offers) return [];
      const start = this.offers_current_page * this.offers_rows_per_page;
      const end = start + this.offers_rows_per_page;
      return this.merchant.offers.slice(start, end);
    }
  },
  mounted() {
    this.get_merhant();
  },
  methods: {
    update_merchant() {
        this.confirm_loader = true;
        axios.post(this.api_url+'superadmin/update-merchant-data',
        {
            status : this.merchant_status,
            merchant_id : this.merchant.id,
            set_user_inactive : this.merchant_status == 'inactive' || this.merchant_status == 'trashed' ? 1 : 0
        },
        {
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
        }
        }).then(response => {
            if (response.data.data.status) {
                this.confirm_loader = false;
                this.confirm_dialogue = false;
                this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
            } else {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.data.message, life: 3000 });
            }
            this.$emit('hide_active_merchant_detail', 'listing-page');
        });
    },
    on_rewards_page_change(event) {
      this.rewards_current_page = event.page;
    },
    on_offers_page_change(event) {
      this.offers_current_page = event.page;
    },
    async generateThumbnail() {
      const canvas = this.$refs.pdfCanvas;
      const context = canvas.getContext('2d');

      try {
        const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });

        // Adjust canvas size to match the PDF page
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render the PDF page to the canvas
        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;

        // Convert the canvas content to a data URL
        this.thumbnail = canvas.toDataURL();
      } catch (error) {
        console.error('Error generating thumbnail:', error);
      }
    

    },
    find_category(data){
            
        var idsInArray2 = data.service_categories.map(item => item.merchant_category_id);
        var matchingArray = this.merchant_categories.filter(item => idsInArray2.includes(item.id));
        return matchingArray;

    },
    get_merhant() {
      axios.post(this.api_url+'superadmin/get-merchant',
      {
        merchant_id : this.merchant_id
      },
      {
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem('wafa_super_admin_auth_token')
      }
          }).then(response => {
              this.merchant = response.data.data;
              this.merchant.created_at = moment(this.merchant.created_at).format("DD MMM YYYY");
              this.pdfUrl = this.merchant.trade_license;
              this.generateThumbnail();
          });
      },
  } 
}
</script>

<style>

</style>
  