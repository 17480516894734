<template>

  <sidebarNav />

  <div class="page-header">
    <div class="header-left-area">
      <h1 class="page-title m-0">Settings</h1>
    </div>
    
    <div class="header-right-area">
      <h2 class="haeder-user-name">
        Azka Mazza
      </h2>
      <div class="user-profile-img-circle">
        <img src="../../assets/images/default.png">
      </div>
    </div>
  </div>

  <div class="app-content content">
    <div class="container-fuild listing-container users-setting-container">
      <div class="user-activity-inner settting-inner-container mt-1">
 
          <Tabs-prime value="0">
              <TabList-prime class="account-info-tablist">
                  <Tab-prime class="account-information-tab" value="0">Account Information</Tab-prime>
                  <Tab-prime value="1">Subscriptions</Tab-prime>

                  <span class="user-header-btn">
                      <button-prime class="setting-save-btn" label="Save" />
                  </span>
              </TabList-prime>

              <TabPanels-prime>
                  <TabPanel-prime value="0">
                      <div class="grid m-0 mt-3">
                          <div class="col-12 md:col-12 lg:col-12">
                            <div class="upload-user-profile-bg">
                              <p>Add Cover Picture here</p>
                            </div>
                            <div class="upload-user-profile-img">
                              <img src="../../assets/images/default.png">
                              <i class="pi pi-camera"></i>
                            </div>
                          </div>
                      </div>
                      
                      <div class="grid m-0 mt-3">
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Brand Name</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/name.svg">
                                    <inputText-prime class="form-input-fields" placeholder="Enter Brand Name" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Email Address</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/sms.svg">
                                    <inputText-prime class="form-input-fields" type="email" placeholder="Enter Your Email" />
                                </span>
                            </div>
                          </div>
                          
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Phone Number</label>
                                <span class="p-input-icon-left">
                                    <vue-tel-input v-model="signup_phone" mode="international" placeholder="Write your mobile number"></vue-tel-input>
                                </span>
                            </div>
                          </div>
                          
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Service Category</label>
                                <span class="p-input-icon-left">
                                  <img class="inputFields-icons" src="../../assets/images/settings-icons/category.svg">
                                  <multiSelect-prime v-model="selectedCategory" :options="categories" optionLabel="name" filter placeholder="Select Cities" :maxSelectedLabels="5" class="w-full" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Location</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                    <inputText-prime class="form-input-fields" placeholder="Enter Branch Location here" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Emirate</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                    <inputText-prime class="form-input-fields" placeholder="Enter Emirate" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-12 lg:col-12">
                            <div class="form-fields-main mb-0">
                              <label>Choose Location on Map</label>
                              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus" width="100%" height="330" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                          </div>
                      </div>

                      <div class="grid m-0 mt-0">
                          <div class="col-12 md:col-12 lg:col-12">
                            <h2 class="user-setting-title">Change Password</h2>
                          </div>
                          
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/Lock.svg">
                                    <password-prime class="form-input-fields" toggleMask :feedback="false" placeholder="Enter old password" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/Lock.svg">
                                    <password-prime class="form-input-fields" toggleMask :feedback="false" placeholder="Enter new password" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/Lock.svg">
                                    <password-prime class="form-input-fields" toggleMask :feedback="false" placeholder="Confirm new password" />
                                </span>
                            </div>
                          </div>
                          
                          <div class="col-12 md:col-12 lg:col-12">
                            <button-prime class="wafa-primary-btn change-password-btn" label="Change Password" />
                          </div>
                      </div>
                  </TabPanel-prime>

                  <TabPanel-prime value="1">
                    <div class="pricing-info-container">
                      <h2 class="pricing-info-title">Simple Plans, <span>Free</span> To Try</h2>
                      <p class="pricing-info-label">Get 3 months <span>Free Trial</span> on all Plans</p>

                      <div class="grid group pricing-container">
                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free.svg">
                              </div>
                              <h2>WAFA FREEMIUM</h2>
                              <h3>
                                0<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-times-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn"></div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free-1.svg">
                              </div>
                              <h2>WAFA BASIC</h2>
                              <h3>
                                99<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">2</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-times-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                              </div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free-2.svg">
                              </div>
                              <h2>WAFA PLUS</h2>
                              <h3>
                                199<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">5</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <p class="m-0">2</p>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">5</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                              </div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free-3.svg">
                              </div>
                              <h2>WAFA ENTERPRISE</h2>
                              <h3>
                                399<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                              </div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel-prime>
              </TabPanels-prime>
          </Tabs-prime>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const selectedCategory = ref();
const categories = ref([
    { name: 'Food' },
    { name: 'Beauty & Spa' },
    { name: 'Fitness' },
    { name: 'Clinical'},
    { name: 'Shopping' }
]);
</script>

<script>
import sidebarNav from '../sharedViews/sharedView.vue';

export default {
  components: {
      sidebarNav,
  },
  data() {
    return {
    }
  },
  methods: {
  } 
}
</script>

<style>

</style>
